<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="check-password-strength d-flex justify-center align-center"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <div
            class="flex element mr-2"
            :class="{
              'bg-very-weak': passwordStrength === passwordStrengthEnum.VERY_WEAK,
              'bg-weak': passwordStrength === passwordStrengthEnum.WEAK,
              'bg-good': passwordStrength === passwordStrengthEnum.GOOD
            }"
          />
          <div
            class="flex element mr-2"
            :class="{
              'bg-weak': passwordStrength === passwordStrengthEnum.WEAK,
              'bg-good': passwordStrength === passwordStrengthEnum.GOOD
            }"
          />
          <div
            class="flex element mr-2"
            :class="{
              'bg-good': passwordStrength === passwordStrengthEnum.GOOD
            }"
          />
          <div
            class="d-flex flex justify-end align-center"
            :class="{
              'text-very-weak': passwordStrength === passwordStrengthEnum.VERY_WEAK,
              'text-weak': passwordStrength === passwordStrengthEnum.WEAK,
              'text-good': passwordStrength === passwordStrengthEnum.GOOD
            }"
          >
          {{ passwordStrengthText }}
        </div>
        </div>
      </template>
      <span>
        <p class="ma-0"> {{ $t('passwordStrength.passwordStrengthTip') }} </p>
        <p class="ma-0"> {{ $t('passwordStrength.smallLetters') }} </p>
        <p class="ma-0"> {{ $t('passwordStrength.capitalLetters') }} </p>
        <p class="ma-0"> {{ $t('passwordStrength.specialCharacters') }} </p>
        <p class="ma-0"> {{ $t('passwordStrength.digits') }} </p>

      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { containsSpecialChars, containsDigits } from '@/utils/helpers'

export default {
  name: 'CheckPasswordStrength',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      passwordStrengthEnum: {
        VERY_WEAK: 0,
        WEAK: 1,
        GOOD: 2
      },
      show: true
    }
  },
  computed: {
    passwordStrength () {
      if (containsSpecialChars(this.value) &&
          containsDigits(this.value) &&
           this.value.length >= 8) {
        return this.passwordStrengthEnum.GOOD
      } else if ((containsDigits(this.value) &&
                 this.value.length >= 8) ||
                 this.value.length >= 12) {
        return this.passwordStrengthEnum.WEAK
      } else {
        return this.passwordStrengthEnum.VERY_WEAK
      }
    },
    passwordStrengthText () {
      if (containsSpecialChars(this.value) &&
          containsDigits(this.value) &&
           this.value.length >= 8) {
        return this.$t('passwordStrength.good')
      } else if ((containsDigits(this.value) &&
                 this.value.length >= 8) ||
                 this.value.length >= 12) {
        return this.$t('passwordStrength.weak')
      } else {
        return this.$t('passwordStrength.veryWeak')
      }
    },
    passwordStrengthTips () {
      return this.$t('passwordStrength.passwordStrengthTips')
    }
  }
}
</script>

<style lang="scss" scoped>
.check-password-strength div {
  width: 100%;
}

.element {
  background: var(--v-secondary-lighten5);
  height: 8px;

  &.bg-very-weak {
    background: var(--v-error-base);
  }

  &.bg-weak {
    background: var(--v-warning-base);
  }

  &.bg-good {
    background: var(--v-success-base);
  }
}
.text-very-weak {
  color: var(--v-error-base);
}

.text-weak {
  color: var(--v-warning-base);
}

.text-good {
  color: var(--v-success-base);
}
</style>
