<template>
  <div class="pa-6">
    <v-card outlined flat>
      <v-card-text class="pa-4">
        <template v-for="agreement in agreements">
          <v-checkbox :key="agreement.value" v-model="agreement.accepted" :readonly="agreement.required" hide-details @change="changeAgreement(agreement)" class="mt-2">
            <template #label>
              <span><span v-if="agreement.required" class="red--text font-weight-bold">*</span> <div class="agreement-header" v-html="agreement.text" @click.stop></div></span>
            </template>
          </v-checkbox>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Service from '@/services'
import { mapGetters } from 'vuex'

export default {
  name: 'Consent',
  data () {
    return {
      loading: false,
      agreements: []
    }
  },
  computed: {
    ...mapGetters({
      isMarketUser: 'isMarketUser'
    })
  },
  async created () {
    await this.getUser()
  },
  methods: {
    async getUser () {
      this.loading = true
      try {
        if (this.isMarketUser || this.$store.getters.isDriver) {
          const { data } = await Service.get('/User/GetCurrentCompanyUser')
          // TODO: Zmienić pole na backu - literówka
          this.agreements = data.aggrements
        } else {
          const { data } = await Service.get('/User/GetCurrentUser')
          this.agreements = data.aggrements
        }
      } finally {
        this.loading = false
      }
    },
    async changeAgreement (agreement) {
      try {
        await Service.put('/User/UpdateOptionalAgreements', {
          agreement: agreement.value
        })
        await this.$store.dispatch('setSuccessNotification', this.$t('common.confirmOperation'))
      } catch (e) {}
    }
  }
}
</script>
