var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"check-password-strength d-flex justify-center align-center",attrs:{"color":"primary","dark":""}},'div',attrs,false),on),[_c('div',{staticClass:"flex element mr-2",class:{
            'bg-very-weak': _vm.passwordStrength === _vm.passwordStrengthEnum.VERY_WEAK,
            'bg-weak': _vm.passwordStrength === _vm.passwordStrengthEnum.WEAK,
            'bg-good': _vm.passwordStrength === _vm.passwordStrengthEnum.GOOD
          }}),_c('div',{staticClass:"flex element mr-2",class:{
            'bg-weak': _vm.passwordStrength === _vm.passwordStrengthEnum.WEAK,
            'bg-good': _vm.passwordStrength === _vm.passwordStrengthEnum.GOOD
          }}),_c('div',{staticClass:"flex element mr-2",class:{
            'bg-good': _vm.passwordStrength === _vm.passwordStrengthEnum.GOOD
          }}),_c('div',{staticClass:"d-flex flex justify-end align-center",class:{
            'text-very-weak': _vm.passwordStrength === _vm.passwordStrengthEnum.VERY_WEAK,
            'text-weak': _vm.passwordStrength === _vm.passwordStrengthEnum.WEAK,
            'text-good': _vm.passwordStrength === _vm.passwordStrengthEnum.GOOD
          }},[_vm._v(" "+_vm._s(_vm.passwordStrengthText)+" ")])])]}}])},[_c('span',[_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t('passwordStrength.passwordStrengthTip'))+" ")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t('passwordStrength.smallLetters'))+" ")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t('passwordStrength.capitalLetters'))+" ")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t('passwordStrength.specialCharacters'))+" ")]),_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.$t('passwordStrength.digits'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }