<template>
  <v-dialog v-if="device" v-model="dialog" persistent width="350px">
    <v-card outlined>
      <v-card-title class="mb-4">
        <h4 class="primary--text">{{ $t('profile.devices.title2') }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form ref="updateDeviceForm" v-model="valid">
          <v-text-field
            v-model="newName"
            outlined
            dense
            single-line
            :rules="required"
          ></v-text-field>
        </v-form>
        <v-card-actions class="pa-0">
          <v-btn color="primary" outlined min-width="120" :disabled="loading" @click="cancel">
            {{ $t('profile.devices.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" min-width="120" :loading="loading" :disabled="loading" @click="submit" depressed >
            {{ $t('profile.devices.save') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'UpdateDevice',
  props: ['dialog', 'device', 'loading'],
  data () {
    return {
      name: this.device.name,
      valid: true,
      required: [(v) => !!v || this.$t('validation.fieldRequired')]
    }
  },
  computed: {
    newName: {
      get () {
        return this.device.name
      },
      set (newValue) {
        this.name = newValue
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
      this.$refs.updateDeviceForm.resetValidation()
    },
    submit () {
      this.$refs.updateDeviceForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit', this.name)
      this.$refs.updateDeviceForm.resetValidation()
    }
  }
}
</script>
